import React, { useEffect } from 'react'

const Footer = () => {

  useEffect(() => {
    var currentYear = new Date().getFullYear();

    // Find the element with the id "year"
    var yearElement = document.getElementById("year");

    // Update the content of the element with the current year
    yearElement.textContent = currentYear;
  }, [])

  return (
    <>

      <div id='contact' className='container mx-auto md:pt-20 pt-10 pb-10 md:flex justify-center items-center md:px-20'>
        <div className='md:w-2/4 mx-3 md:mx-0 flex justify-center'>
          <div>
            <p className='text-3xl font-bold pb-8'>Contact Us</p>
            <div className='flex mb-6'>
              <div><i class="text-2xl me-6 fa-solid fa-location-dot"></i></div>
              <div><p className='md:text-xl'>No(803), Innwa Street, 6th quarter, <br /> South Oakkalar, Yangon Region</p></div>
            </div>
            <div className='flex mb-6'>
              <div><i class="text-2xl me-6 fa-solid fa-phone-volume"></i></div>
              <div><p className='md:text-xl'>(+95) 95182755</p></div>
            </div>
            <div className='flex mb-6'>
              <div><i class="text-2xl me-6 fa-solid fa-solid fa-envelope"></i></div>
              <div><p className='md:text-xl'>momicheladieswears@gmail.com</p></div>
            </div>

            <p className='text-2xl font-bold pb-8 flex'>
              <div className='me-8'>Follow Us:</div>
              <div>
                <a href="https://www.facebook.com/momicheladieswear?mibextid=LQQJ4d"><i class="text-2xl me-4 fa-brands fa-facebook"></i></a>
                <a href="#"><i class="text-2xl me-4 fa-brands fa-square-instagram"></i></a>
                <a href="https://www.tiktok.com/@yadanara61?_t=8iWTPQFpVe4&_r=1"><i class="text-2xl me-4 fa-brands fa-tiktok"></i></a>
              </div>   </p>
          </div>


        </div>
        <div className=' md:w-2/4 flex justify-center'>
          <div className='md:w-[350px]'>
            <a href="/" className="md:justify-center space-x-3 rtl:space-x-reverse relative">
              <div className="h-[90px] w-[213px] rounded-lg bg-black flex justify-center my-2">
                <img src="./logo.png" alt="momiche Logo" />
              </div>
            </a>
            <div>
              <p>We're open</p>
              <p className='font-bold text-xl'>from 10 AM to 5:30 PM</p>
              <button style={{ background: 'red' }} type="button" className="text-white mt-2 w-[210px] bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Closed Every Monday!</button>
            </div>
          </div>


        </div>
      </div>

      {/* <div className='flex md:mx-9 py-8'>
            <div className='w-[26%]'>
                <img src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/logo.svg" className="h-18" alt="Flowbite Logo" />
                <p className="flex"><span><i class="pr-4 fa-solid fa-phone text-3xl"></i></span><span className="text-[1.2rem]">(+95) 9887288807 </span> </p>
                <p className="flex"><span><i class="pr-4 fa-regular fa-envelope text-3xl"></i></span><span className="text-[1.2rem]">support@renemattias.com</span></p>

            </div>
            <div className='w-[12%]'>
                <p className='text-xl pb-3'>SHOP</p>
                <ul>
                    <li className='py-2 text-[1.1rem]'>Best Seller</li>
                    <li className='py-2 text-[1.1rem]'>Clothing</li>
                    <li className='py-2 text-[1.1rem]'>Occasions</li>
                    <li className='py-2 text-[1.1rem]'>Sales</li>
                </ul>
            </div>
            <div className='w-[18%]'>
            <p className='text-xl pb-3'>NEED HELP</p>

                <ul><li className='py-2 text-[1.1rem]'>My account</li>
                    <li className='py-2 text-[1.1rem]'>Customer Care</li>
                    <li className='py-2 text-[1.1rem]'>Store Location</li>
                    <li className='py-2 text-[1.1rem]'>Blogs</li>
                    <li className='py-2 text-[1.1rem]'>Fit & size</li>
                    <li className='py-2 text-[1.1rem]'>Check my wallet balance</li>
                </ul>
            </div>
            <div className='w-[14%]'>
            <p className='text-xl pb-3'>SOCIAL MEDIA</p>

                <ul>
                    <li className='py-2 text-[1.1rem]'>Facebook</li>
                    <li className='py-2 text-[1.1rem]'>Instagram</li>
                    <li className='py-2 text-[1.1rem]'>Twitter</li>
                    <li className='py-2 text-[1.1rem]'>LinkedIn</li>
                    <li className='py-2 text-[1.1rem]'>Youtube</li>
                </ul>
            </div>
            <div className='w-[30%]'>
            <p className='text-xl pb-3'>SIGN UP FOR THE NEWSLETTERS</p>

                <p className='py-1 text-[1.1rem] mb-2'>Sign up for our newsletter and get the great deal right in you</p>
                <div className='flex mb-3'>
                     <input type="text" placeholder='Enter email' />
                <button className='px-2 bg-black text-white'>Subscribe now</button>
                </div>
               
               <img src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/payment-section.png" alt="" />
            </div>
        </div> */}


      <div class="bg-[#333333] text-white text-center border-dotted border-t-2 p-8">
        <p class="md:text-xl" id="copyright-year">© <span id="year"></span> Momiche. All
          Rights Reserved. Designed by <span><a className='border-b'
            href="https://ethicaldigit.com/">Ethical Digit</a></span>
        </p>

      </div>
    </>
  )
}

export default Footer