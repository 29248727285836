export default [
    {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['yellow', 'pink'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['cyan', 'gray'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress 2',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: 'https://files.renemattias.com/media/catalog/product/cache/f835f122baa888df8d736bf20aa8a058/2/3/230503rene3865.jpg'
    },
]