import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();

    console.log(location)

    const isHome = location.hash === '#home';
    const isBestSeller = location.hash === '#bestSeller';
    const isClothings = location.pathname === '/clothings/';
    const isServices = location.hash === '#services';
    const isAbout = location.hash === '#about';
    const isContact = location.hash === '#contact';
    const dailyWear = location.hash === '#dailyWear';
    const officeWear = location.hash === '#officeWear'
    const cottonHandmade = location.hash === '#cottonHandmade'
    const eventWear = location.hash === '#eventWear'




    const [showMenu, setShowmenu] = useState(false)
    const [openDD, setOpenDD] = useState(false)


    return (


        <nav className="bg-white border-gray-200 dark:bg-gray-900 ">
            {/* max-w-screen-xl */}
            <div className=" flex-wrap md:items-center md:justify-center mx-auto">
                <a className="flex items-center md:justify-center space-x-3 rtl:space-x-reverse relative border">
                    <Link to='/#home'>
                        <div className="md:h-[90px] h-[60px] ms-2 md:ms-0 md:w-[213px] w-[130px] rounded-lg bg-black flex justify-center my-2">
                            <img src="./logo.png" alt="momiche Logo" />
                        </div>
                    </Link>



                    <div className='absolute top-50 md:right-10 right-2 flex'>
                        {/* <svg className="w-5 h-5 me-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                        <svg className="w-5 h-5 me-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg> */}
                        <i className="hidden md:block text-2xl me-6 fa-brands fa-facebook"></i>
                        <i className="hidden md:block text-2xl me-6 fa-brands fa-square-instagram"></i>
                        <i className="hidden md:block text-2xl me-6 fa-brands fa-tiktok"></i>

                        <button onClick={() => setShowmenu(!showMenu)} data-collapse-toggle="navbar-one" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-one" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>
                    {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
                </a>
                {/* <button data-collapse-toggle="navbar-one" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-one" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button> */}
                {/* <button data-collapse-toggle="navbar-one" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-one" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button> */}
                <div className={`${showMenu ? 'md:hidden' : 'hidden'} w-full md:block md:w-auto" id="navbar-one`}>
                    <ul className=" font-medium flex md:justify-center md:items-center flex-col p-4 md:p-3 mt-4 border border-gray-100 bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-[#FC0] dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#home" className={`block md:text-xl font-bold py-2 px-3  ${isHome && 'bg-blue-700'} rounded md:bg-transparent ${isHome ? 'md:text-blue-700' : 'lg:text-black'}  md:p-0 dark:text-white md:dark:text-blue-500`}>Home</Link>
                        </li>
                        <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#bestSeller" className={`block md:text-xl font-bold py-2 px-3 ${isBestSeller && 'bg-blue-700'} md:bg-transparent  ${isBestSeller ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Best Seller</Link>
                        </li>
                        {/* <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#clothings" className={`block md:text-xl font-bold py-2 px-3 ${isClothings && 'bg-blue-700'} md:bg-transparent ${isClothings ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Clothings</Link>
                        </li> */}
                         <li className='relative'>
                            <button onClick={() => {setOpenDD(!openDD);}} id="dropdownNavbarLinkHome" data-dropdown-toggle="dropdownNavbarHome"
                                class={`${isClothings && 'bg-blue-700'} md:bg-transparent ${isClothings ? 'md:text-blue-700' : 'lg:text-black'} flex items-center mx-3 md:text-xl font-bold justify-between w-full py-2 pl-3 pr-4  text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent`}>Clothings
                                <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 1 4 4 4-4" />
                                </svg></button>
                            <div
                                class={`z-30 ${openDD ? '' : 'hidden'} w-full md:w-[200px] absolute md:bottom-[-150px] font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600`}>
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-400"
                                    aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link onClick={() => {setOpenDD(!openDD); setShowmenu(false);}} to='/clothings/#dailyWear' className={`block font-bold my-2 py-2 px-2 mx-3 ${dailyWear && 'bg-blue-700'} md:bg-transparent ${dailyWear ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Daily Wear</Link>
                                    </li>
                                    <li>
                                        <Link onClick={() => {setOpenDD(!openDD); setShowmenu(false);}} to='/clothings/#officeWear' className={`block font-bold my-2 py-2 px-2 mx-3 ${officeWear && 'bg-blue-700'} md:bg-transparent ${officeWear ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Office Wear</Link>

                                    </li>
                                    <li>
                                        <Link onClick={() => {setOpenDD(!openDD); setShowmenu(false);}} to='/clothings/#cottonHandmade' className={`block font-bold my-2 py-2 px-2 mx-3 ${cottonHandmade && 'bg-blue-700'} md:bg-transparent ${cottonHandmade ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Cotton & Handmade</Link>

                                    </li>
                                    <li>
                                        <Link onClick={() => {setOpenDD(!openDD); setShowmenu(false);}} to='/clothings/#eventWear' className={`block font-bold my-2 py-2 px-2 mx-3 ${eventWear && 'bg-blue-700'} md:bg-transparent ${eventWear ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Event Wear</Link>
                                    </li>
                                </ul>

                            </div>
                        </li>
                        <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#services" className={`block md:text-xl font-bold py-2 px-3 ${isServices && 'bg-blue-700'} md:bg-transparent ${isServices ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Services</Link>
                        </li>
                        <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#about" className={`block md:text-xl font-bold py-2 px-3 ${isAbout && 'bg-blue-700'} md:bg-transparent ${isAbout ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>About</Link>
                        </li>
                        <li className='px-3' onClick={() => setShowmenu(false)}>
                            <Link to="/#contact" className={`block md:text-xl font-bold py-2 px-3 ${isContact && 'bg-blue-700'} md:bg-transparent ${isContact ? 'md:text-blue-700' : 'lg:text-black'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>Contact</Link>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default Navbar