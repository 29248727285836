import React from 'react';

import Navbar from "./Navbar.jsx";
import data1 from './data/Trandy1.js'
import data2 from './data/Trandy2.js'
import data3 from './data/Trandy3.js'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from "react";
import Footer from './Footer.jsx';
import { Link, useLocation } from 'react-router-dom';




function App() {

  const [trandy, setTrandy] = useState('1')
  const [trandyData, setTrandyData] = useState(null)
  const location = useLocation();

  console.log(trandyData)
  console.log(trandy)

  useEffect(() => {
    // Check if there is a hash in the URL
    if (location.hash) {
      const elementId = location.hash.substring(1); // Remove the '#' from the hash
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If there is no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    if (trandy == '1') {
      setTrandyData(data1)
    }
    if (trandy == '2') {
      setTrandyData(data2)
    }
    if (trandy == '3') {
      setTrandyData(data3)
    }
  }, [trandy])

  const CustomNextArrow = ({ onClick }) => (
    <div className="custom-next-arrow shadow-md hover:shadow-lg focus:shadow-xl" onClick={onClick}>
      <i class="fa-solid fa-chevron-right"></i>
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div className="custom-prev-arrow shadow-md hover:shadow-lg focus:shadow-xl" onClick={onClick}>
      <i class="fa-solid fa-chevron-left"></i>
    </div>
  );

  var settings0 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  var settings2 = {
    // dots: true,
    infinite: true,
    speed: 400,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <div className="App">
      {/* <Navbar /> */}


      <div id='home' className='container md:flex flex-row-reverse justify-center items-center mx-auto md:p-10 p-3'>
        <div className=' md:w-2/4 flex justify-center items-center md:py-8 py-4'>
          <div className='md:me-4 me-3 w-2/4 md:w-auto'>
            <div className='md:w-[250px] w-[98%] h-[300px] border bg-[#D9D9D9] rounded-lg md:mb-4 mb-3'><img className='w-full h-full object-cover rounded-lg' src="/2.jpeg" alt="" /></div>
            <div className='md:w-[250px] w-[98%] h-[170px] border bg-[#D9D9D9] rounded-lg'><img className='w-full h-full object-cover rounded-lg' src="/1.jpeg" alt="" /></div>
          </div>
          <div className=' w-2/4 md:w-auto'>
            <div className='md:w-[250px] w-[98%] h-[300px] border bg-[#D9D9D9] rounded-lg'><img className='w-full h-full object-cover rounded-lg' src="/3.jpeg" alt="" /></div>
            <p className='text-3xl font-bold md:w-[250px] pt-2'>Let's make more beautiful.</p>
          </div>

        </div>

        <div className=' md:w-2/4 h-auto'>
          <p className='md:text-5xl text-3xl font-bold leading-tight font-sans'>Welcome to MOMICHE<br /> Clothing ! </p>
          <div className='w-[100px] h-[4px] my-5 bg-black'></div>
          <p className=' text-justify mb-8'>Founded in 2016,  MOMICHE emerged from a passion for creating clothing that transcends boundaries and celebrates the spirit of our local community.
            As a unique local clothing brand, Inspired by the vibrant colors, cotton, traditions, handmade and comfortable design that we set out to define local fashion.
            Our commitment to sustainability starts with the careful selection of cotton, ethically sourced from local farms. We prioritize organic and eco-friendly materials
          </p>
          <p className=' text-justify mb-2 font-bold'>“Happy Shopping with us !"</p>

          <Link to="/#clothings">
            <button style={{ background: '#FC0' }} type="button" class="w-[230px] h-[65px] flex justify-center items-center py-2.5 me-2 mb-2  font-medium focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <span className='pe-2'>View Collections</span>  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M2.20831 12H23.0416" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.7083 5L24 12L16.7083 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button></Link>

          {/* <button>View Collections</button> */}
        </div>

      </div>

      <div id='bestSeller' className='container mx-auto px-3 md:px-0'>
        <p className='md:text-5xl text-3xl font-bold md:pb-8 py-3 font-sans'>Best Seller</p>
        <div className="w-full">
          <Slider {...settings0}>
            {trandyData?.map((each) => (
              <div className=" p-2 outline-none">
                < img src={each.image} className="rounded w-full h-[350px] object-cover" alt="" />
                {/* <h3 className="text-xl font-bold pt-2">{each.title}</h3> */}
                {/* <p className="pb-2">{each.price}</p> */}
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div id='clothings' className='container mx-auto pt-10  px-3 md:px-0'>
        <p style={{ color: 'rgba(0, 0, 0, 0.70)' }} className='text-[rgba(0, 0, 0, 0.70)] md:text-xl'>Our local brand ...</p>
        <p className='md:text-5xl text-3xl font-bold pb-8 font-sans'>Clothing</p>
        <div className="w-full md:flex gap-4">
          <div className='w-full  rounded-lg mb-4'><Link to='/clothings/#dailyWear'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/IMG_3237.JPG" alt="" /><p className='pt-2 pb-1 text-2xl font-bold'>Daily Wear</p></Link></div>
          <div className='w-full  rounded-lg mb-4'><Link to='/clothings/#officeWear'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/10.jpg" alt="" /><p className='pt-2 pb-1 text-2xl font-bold'>Office Wear</p></Link></div>
          <div className='w-full  rounded-lg mb-4'><Link to='/clothings/#cottonHandmade'><img className='w-full h-[300px] object-cover rounded-lg' src="/1.jpeg" alt="" /><p className='pt-2 pb-1 text-2xl font-bold'>Cotton & Handmade</p></Link></div>
          <div className='w-full  rounded-lg mb-4'><Link to='/clothings/#eventWear'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/IMG_3256.JPG" alt="" /><p className='pt-2 pb-1 text-2xl font-bold'>Event Wear</p></Link></div>

        </div>
      </div>

      <div id='services' className="container mx-auto py-10 px-3 md:px-0">
        <p className='md:text-5xl text-3xl font-bold pb-8 font-sans'>Our Services</p>
        <div className="w-full md:flex gap-5 justify-center text-center">
          <div className='w-full px-1 md:h-[300px] h-[250px] rounded-lg mb-4'>
            <img className='w-14 h-16 object-contain mx-auto' src="/dressing-room.png" alt="" />
            <p className='pt-2 pb-1 text-2xl font-bold h-[70px]'>Fitting Room <br /><span className='text-xl font-bold'>(Only shop)</span></p>
            <p className=''>Welcome to Our Fitting Room - Where Your Perfect Fit Awaits. Feel free to try  the fitting experience and embrace the fabulous you. </p></div>
          <div className='w-full px-1 md:h-[300px] h-[250px] rounded-lg mb-4'>
            <img className='w-14 h-16 object-contain mx-auto' src="/price.svg" alt="" />
            <p className='pt-2 pb-1 text-2xl font-bold h-[70px]'>Comfort Price</p>
            <p className=''>We believe in the comfort of both style and budget. Our Comfort Price is designed to make fashion accessible to everyone. Discover the ease of Comfort Price at MOMICHE.</p></div>
          <div className='w-full px-1 md:h-[300px] h-[250px] rounded-lg mb-4'>
            <img className='w-14 h-16 object-contain mx-auto' src="/hq.svg" alt="" />
            <p className='pt-2 pb-1 text-2xl font-bold h-[70px]'>High Quality</p>
            <p className=''>Our commitment to quality craftsmanship and high-quality cotton can provide. We ensuring that each piece is not just clothing and our garments are more than just a statement of enduring quality. </p></div>
          <div className='w-full px-1 md:h-[300px] h-[250px] rounded-lg mb-4'>
            <img className='w-14 h-16 object-contain mx-auto' src="/truck.svg" alt="" />
            <p className='pt-2 pb-1 text-2xl font-bold h-[70px]'>Fast Delivery</p>
            <p className=''>Our streamlined delivery process ensures that your latest fashion finds reach you in record time.
              We've partnered with reliable delivery service to bring you the convenience of doorstep delivery.
            </p></div>

        </div>
      </div>

      <div id='about'>
        <p className='md:text-5xl text-3xl font-bold pb-12 md:pb-8 container mx-auto  px-3 md:px-0 font-sans'>About Us</p>
        <div className="relative w-full md:h-[450px] bg-[url('/bg1.jpeg')] bg-cover bg-no-repeat bg-center">
          <div className='w-full h-full flex flex-col justify-center items-center ' style={{ background: "rgba(8, 35, 46, 0.40)" }}>
            <p className='md:px-20 px-3 pt-20 text-white md:text-xl text-center'>"Dress Beyond Impress -  Uniquely Confidence with “MOMICHE” <br /> At MOMICHE clothing shop , we invite you to explore a world where fashion is an extension of your traditional personality, and each piece tells the story of a confident and stylish elegant ladies. <br />Follow us on Instagram/Facebook/Twitter - @MOMICHE</p>
            <div className='flex justify-center py-8 pb-16'>
              <button type="button" class="py-2.5 px-5 me-2 mb-2 text-lg font-medium text-white focus:outline-none bg-transparent rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                See More
              </button>
            </div>
          </div>

          <div className="h-[90px] w-[213px] rounded-lg bg-black flex justify-center my-2 absolute top-[-45px] left-1/2 transform -translate-x-1/2">
            <img src="./logo.png" alt="Flowbite Logo" />
          </div>
        </div>
      </div>



      {/* <div id="gallery" class="relative w-full" data-carousel="slide">
        <div class="relative md:h-[740px] h-[300px] overflow-hidden rounded-lg">
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/First_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item="active">
            <img src="https://files.renemattias.com/media/banner/upload/First_Banner_1_4.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/First_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/First_Banner_1_4.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/First_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
        </div>
        <button type="button"
          class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev>
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 1 1 5l4 4" />
            </svg>
            <span class="sr-only">Previous</span>
          </span>
        </button>
        <button type="button"
          class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next>
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
            <span class="sr-only">Next</span>
          </span>
        </button>
      </div>

      <div className="flex w-[95%] border-1 mx-auto pt-9 ">
        <div className="w-1/4 text-center">
          <p className="text-4xl  font-bold mb-4">Trendy Now</p>
          <button onClick={() => setTrandy('1')} className="border w-[250px] p-2 rounded mb-2 shadow-md hover:shadow-lg focus:shadow-xl  text-xl">New In</button> <br />
          <button onClick={() => setTrandy('2')} className="border w-[250px] p-2 rounded mb-2 shadow-md hover:shadow-lg focus:shadow-xl  text-xl">Best Sellers</button><br />
          <button onClick={() => setTrandy('3')} className="border w-[250px] p-2 rounded mb-2 shadow-md hover:shadow-lg focus:shadow-xl  text-xl">Top Picks</button>


        </div>
        <div className="w-3/4">
          <Slider {...settings}>
            {trandyData?.map((each) => (
              <div className=" p-2">
                < img src={each.image} className="rounded" alt="" />
                <h3 className="text-xl font-bold">{each.title}</h3>
                <p className="pb-2">{each.price}</p>
                <div className="flex">
                  {each?.color?.map((eachColor) => (
                    <p className={`text-[${eachColor}] border-1 w-5 h-5 bg-[${eachColor}] rounded-full mr-1`}></p>
                  ))}
                </div>

              </div>
            ))}
          </Slider>
        </div>


      </div >


      <div id="gallery" class="relative w-full" data-carousel="slide">
        <div class="relative md:h-[740px] h-[300px] overflow-hidden rounded-lg">
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/Second_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item="active">
            <img src="https://files.renemattias.com/media/banner/upload/Second_Banner_1_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/Second_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/Second_Banner_1_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
          <div class="hidden duration-700 ease-in-out" data-carousel-item>
            <img src="https://files.renemattias.com/media/banner/upload/Second_Banner_2_5.jpg" class="absolute block max-w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="" />
          </div>
        </div>
        <button type="button"
          class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-prev>
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 1 1 5l4 4" />
            </svg>
            <span class="sr-only">Previous</span>
          </span>
        </button>
        <button type="button"
          class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          data-carousel-next>
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
            <span class="sr-only">Next</span>
          </span>
        </button>
      </div>

      <div className="w-[98%] mx-auto bg-[#F5F3EE] py-10 px-2">
        <h1 className="text-4xl text-center pb-10 font-semibold">Let’s Find Your Wear</h1>
        <Slider {...settings2}>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/6.jpg' className="rounded" alt="" />
          </div>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/5_2.jpg' className="rounded" alt="" />
          </div>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/7_1.jpg' className="rounded" alt="" />
          </div>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/6.jpg' className="rounded" alt="" />
          </div>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/5_2.jpg' className="rounded" alt="" />
          </div>
          <div className="p-1">
            < img src='https://www.renemattias.com/media/catalog/category/7_1.jpg' className="rounded" alt="" />
          </div>
        </Slider>
      </div>


      <div className='flex px-3 flex-wrap pt-8'>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/City_Mall.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2 flex justify-center items-center text-center'>
          <div>
            <p className='text-3xl pb-2'>Our Stores</p>
            <p><i class="fa-solid fa-location-dot text-2xl pe-3"></i> Find location</p>
          </div>
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/Times_City.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/Myanmar_Plaza.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/MDY_73.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/MDY_MM_2.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/Pyin_Oo_Lwin.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/Taunggyi_2.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2022-07-05/Lashio.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2023-09-27/6.jpg" alt="" />
        </div>
        <div className='w-1/3 p-2'>
          <img src="https://files.renemattias.com/media/wysiwyg/2023-09-27/Junction_City.jpg" alt="" />
        </div>


      </div>

      <div className=' mx-auto  bg-[#F5F3EE] flex py-20 my-10 text-center'>
        <div className="w-[33%]">
          <img className="mx-auto py-" src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/delivery.svg" alt="" />
          <p className="text-4xl py-3">DELIVERY</p>
          <p className="text-[1.2rem]">Find out the shipping options available for your destination.</p>
          <p className="text-[1.1rem] font-bold">Find out more</p>
        </div>
        <div className="w-[33%]">
          <img className="mx-auto py-" src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/support.svg" alt="" />
          <p className="text-4xl py-3">SUPPORT</p>
          <p className="flex justify-center"><span><i class="pr-4 fa-solid fa-phone text-3xl"></i></span><span className="text-[1.2rem]">(+95) 9887288807 </span> </p>
          <p className="flex justify-center"><span><i class="pr-4 fa-regular fa-envelope text-3xl"></i></span><span className="text-[1.2rem]">support@renemattias.com</span></p>
          <p className="text-[1.1rem] font-bold">Find out more</p>
        </div>
        <div className="w-[33%]">
          <img className="mx-auto py-" src="https://www.renemattias.com/static/version1673335867/frontend/Powergate/rene/en_US/assets/img/exchange.svg" alt="" />
          <p className="text-4xl py-3">EXCHANGE & RETURNS</p>
          <p className="text-[1.2rem]">Arrange your return for an Exchange or refund within 14 days using our free collection service.</p>
          <p className="text-[1.1rem] font-bold">Find out more</p>
        </div>
      </div> */}




      {/* <Footer /> */}
    </div >

  );
}

export default App;
