export default [
    {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/officeWear/11.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['yellow', 'pink'],
        image: '/officeWear/9.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['cyan', 'gray'],
        image: '/officeWear/15.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/4.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/5.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/6.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/officeWear/24.jpg'
    }, {
        title: 'Ruffle Hem Tweed Dress',
        price: '119,900 MMK',
        category: 'JACKETS',
        color: ['green', 'blue'],
        image: '/officeWear/5.jpg'
    }
]