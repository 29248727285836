import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Navbar2 from './Navbar2';

const Clothings = () => {
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    const location = useLocation();

    useEffect(() => {
        // Check if there is a hash in the URL
        if (location.hash) {
            const elementId = location.hash.substring(1); // Remove the '#' from the hash
            const element = document.getElementById(elementId);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // If there is no hash, scroll to the top of the page
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <div>
      {/* <Navbar2 /> */}


            <div id='dailyWear' className='container mx-auto pt-10  px-3 md:px-0'>
                {/* <p style={{ color: 'rgba(0, 0, 0, 0.70)' }} className='text-[rgba(0, 0, 0, 0.70)] text-xl'>Our local brand ...</p> */}
                <p className='text-4xl font-bold pb-8'>Daily Wear</p>
                <p>Available </p>
                <p className='flex'>
                    <div>
                        <p>Top & Longyi</p>
                        <p>Size</p>
                        <p>Cloths</p>
                    </div>
                    <div className='ps-2 pb-3'>
                        <p>: Left side / Right side</p>
                        <p>: S, M, L, XL size </p>
                        <p>: Cotton, Chiffon, etc.,</p>
                    </div>
  
                
              </p>
                <div className="w-full md:flex flex-wrap">
                    {/* <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="https://s3-alpha-sig.figma.com/img/a024/a47e/20e267383608b245fb2e71f23d5848db?Expires=1702857600&Signature=QzCc10A3N8PtFj8gg8y9Fub6FoepUAirVTDqxKavMY8gMgxyGMIPp-QSkDozF7XJa2jjk4MRKraqz-fwJC~wEP70tLWAsGxtC4uQ3mj5tzBHxREzc17ib43oLrIeTQinTA6bAxylv6thmqMBzkWr~RXrY~PzzRujyxWUuIScl-l1yVxeGrUaEr80fpttwyFzuCd7GBocOFZitomSNKkwo1I~xLZLdbOU0iwIqKGb6MY5MmF3-q3vPMAdimPiJvpqaxP7AoZ2EppJZ7wJtEY~uPIhWJVsj1ae3z~Ln-KB~C7vHHLpDHpXZ~OD4etXOJcO6~FxOETvvWaZntRHxwqc3A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" alt="" /><p className='pt-2 pb-1 text-2xl font-bold'>Daily Wear</p><p className='text-[20px]'>Lorem ipsum dolor sit amet consectetur.</p></div> */}
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/1.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/10.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/2.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/8.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/11.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/16.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/3.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/dailyWear/12.jpg" alt="" /></div>

                </div>
            </div>

            <div id='officeWear' className='container mx-auto pt-10  px-3 md:px-0'>
                {/* <p style={{ color: 'rgba(0, 0, 0, 0.70)' }} className='text-[rgba(0, 0, 0, 0.70)] text-xl'>Our local brand ...</p> */}
                <p className='text-4xl font-bold pb-8'>Office Wear</p>
                <p>Available </p>
                <p className='flex'>
                    <div>
                        <p>Top & Longyi</p>
                        <p>Size</p>
                        <p>Cloths</p>
                    </div>
                    <div className='ps-2 pb-3'>
                        <p>: Left side / Right side</p>
                        <p>: S, M, L, XL size </p>
                        <p>: Cotton, Chiffon, etc.,</p>
                    </div>
  
                
              </p>
                <div className="w-full md:flex flex-wrap">
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/15.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/11.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/2.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/9.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/13.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/12.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/14.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/officeWear/17.jpg" alt="" /></div>
                </div>
            </div>

            <div id='cottonHandmade' className='container mx-auto pt-10  px-3 md:px-0'>
                {/* <p style={{ color: 'rgba(0, 0, 0, 0.70)' }} className='text-[rgba(0, 0, 0, 0.70)] text-xl'>Our local brand ...</p> */}
                <p className='text-4xl font-bold pb-8'>Cotton & Handmade</p>
                <p>Available </p>
                <p className='flex'>
                    <div>
                        <p>Top & Longyi</p>
                        <p>Size</p>
                        <p>Cloths</p>
                    </div>
                    <div className='ps-2 pb-3'>
                        <p>: Left side / Right side</p>
                        <p>: S, M, L, XL size </p>
                        <p>: Cotton, Chiffon, etc.,</p>
                    </div>
  
                
              </p>
                <div className="w-full md:flex flex-wrap">
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/1.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/7.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/9.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/2.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/5.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/6.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/10.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/Handmade/8.jpg" alt="" /></div>
                </div>
            </div>

            <div id='eventWear' className='container mx-auto pt-10  px-3 md:px-0'>
                {/* <p style={{ color: 'rgba(0, 0, 0, 0.70)' }} className='text-[rgba(0, 0, 0, 0.70)] text-xl'>Our local brand ...</p> */}
                <p className='text-4xl font-bold pb-8'>Event Wear</p>
                <p>Available </p>
                <p className='flex'>
                    <div>
                        <p>Top & Longyi</p>
                        <p>Size</p>
                        <p>Cloths</p>
                    </div>
                    <div className='ps-2 pb-3'>
                        <p>: Left side / Right side</p>
                        <p>: S, M, L, XL size </p>
                        <p>: Cotton, Chiffon, etc.,</p>
                    </div>
  
                
              </p>
                <div className="w-full md:flex flex-wrap">
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/14.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/3.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/4.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/8.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/12.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/10.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/1.jpg" alt="" /></div>
                    <div className='md:w-[25%] px-2 rounded-lg mb-4'><img className='w-full h-[300px] object-cover rounded-lg' src="/eventWear/9.jpg" alt="" /></div>
                </div>
            </div>
        </div>
    )
}

export default Clothings